import React from "react";

import book from "../images/BookFrontSmall.jpg";
import goforthShow from "../images/goforthshow.jpg";
import aimInsurance from "../images/aimInsurance.png";
import americanDream from "../images/AmericanDreamSmall.jpg";

function Sidebar() {
  return (
    <div className="font-medium">
      <div className="flex justify-center">
        <div className="text-sm text-center">
          <a href="https://www.barnesandnoble.com/w/how-to-sell-homes-in-a-tough-market-jonathan-f-goforth/1105376764?ean=9781463433925" target="_blank" rel="noreferrer">
            <img src={book} alt="My Book" />
          </a>
        </div>
      </div>
      <div className="text-sm text-center pt-2">
        <a href="https://www.barnesandnoble.com/w/how-to-sell-homes-in-a-tough-market-jonathan-f-goforth/1105376764?ean=9781463433925" target="_blank" rel="noreferrer">Order my book from Barnes & Noble</a>
      </div>

      <div className="border-b-2 border-gray-700 my-4 mx-2"></div>

      <div className="flex justify-center">
        <div className="text-sm text-center">
          <a href="https://www.youtube.com/channel/UC7EFJ_G3AmdxZDhOYx0OKzg" target="_blank" rel="noreferrer">
            <img src={goforthShow} alt="Goforth Show" />
            Watch my show on YouTube
          </a>
        </div>
      </div>

      <div className="border-b-2 border-gray-700 my-4 mx-2"></div>

      <div className="flex justify-center">
        <div className="text-sm text-center">
          <a href="https://myaiminsurance.com/" target="_blank" rel="noreferrer">
            <img src={aimInsurance} alt="AIM Insurance" />
          </a>
        </div>
      </div>
      <div className="text-sm text-center pt-2">
        <a href="https://myaiminsurance.com/" target="_blank" rel="noreferrer">Save money on your insurance with AIM Insurance</a>
      </div>

      <div className="border-b-2 border-gray-700 my-4 mx-2"></div>

      <div className="flex justify-center">
        <div className="text-sm text-center">
          <a href="https://www.youtube.com/watch?v=VIOidg0a8B8&list=PL_RUhQBQ8Mnx9AzWrOQO-iSPX7JaN6zDO" target="_blank" rel="noreferrer">
            <img src={americanDream} alt="American Dream" />
          </a>
        </div>
      </div>
      <div className="text-sm text-center pt-2">
        <a href="https://www.youtube.com/watch?v=VIOidg0a8B8&list=PL_RUhQBQ8Mnx9AzWrOQO-iSPX7JaN6zDO" target="_blank" rel="noreferrer">Watch episodes of The American Dream</a>
      </div>
    </div>
  );
}

export default Sidebar;
