import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";
import Sidebar from "./sidebar";

function Layout({ title, children }) {
  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900 bg-red-100">
      <Header title={title} />

      <main className="flex w-full max-w-6xl px-4 py-4 mx-auto md:px-4 md:py-4 bg-red-100">
        <div className="w-3/4">
          {children}
        </div>
        <div className="w-1/4 ml-4 bg-gray-200 border-black border-2 px-2 py-2 rounded">
          <Sidebar />
        </div>
      </main>

      <Footer />
    </div>
  );
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
