import React from "react";

import kwHeader from "../images/kw-header.png";

function Footer() {
  return (
    <footer className="bg-black">
      <nav className="flex max-w-6xl p-4 mx-auto text-sm md:px-4 md:py-4">
        <img src={kwHeader} alt="Keller Williams Logo" className="md:h-24 h-12 object-fill" />
        <p className="text-white text-xs pl-8">
          Keller Williams Platinum Partners<br/>
          3751 NE Ralph Powell Rd.<br/>
          Lee&apos;s Summit, MO  64064<br/>
          Each office is independently owned and operated.<br/>
          Office: <a href="tel:8165257000">816-525-7000</a>  -  Cell: <a href="tel:8168307575">816-830-7575</a><br/>
          <a href="mailto:JGo4th@aol.com">JGo4th@aol.com</a>   -   <a href="mailto:Goforth@KW.com">Goforth@KW.com</a>
        </p>
      </nav>
    </footer>
  );
}

export default Footer;
