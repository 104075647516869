import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import kwHeader from "../images/kw-header.png";

function Header({ title }) {
  const isExpanded = false

  return (
    <>
      <header className="border-black border-b-2 h-20 sm:h-24">
        <div className="flex flex-wrap items-center justify-between max-w-6xl mx-auto" style={{padding: "0.4rem"}}>
          <Link to="/">
            <h1 className="flex items-center no-underline">
              <div className="md:pr-5 md:border-r-2 md:border-red-700">
                <img src={kwHeader} alt="Keller Williams Platinum Partners" className="h-16 sm:h-20 object-fill" />
              </div>
              <div>
                <div className="text-xl lg:text-3xl font-bold md:tracking-normal tracking-tight ml-4">
                  Jonathan Goforth <span className="text-red-700">816.830.7575</span>
                </div>
              </div>
            </h1>
          </Link>

          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } md:block md:flex md:items-center w-full md:w-auto`}
          >
            {[
              {
                route: `/`,
                title: `About`,
              },
              {
                route: `/meet-the-team`,
                title: `Meet The Team`,
              },
            ].map((link) => (
              <Link
                className="block mt-4 no-underline md:inline-block md:mt-0 md:ml-6"
                key={link.title}
                to={link.route}
                activeClassName="font-bold"
              >
                {link.title}
              </Link>
            ))}
          </nav>
        </div>
      </header>
      <div className="h-12 bg-red-700 align-middle">
        <div className="flex items-center justify-between max-w-6xl mx-auto w-full align-middle px-2 py-3 sm:py-2 mx-auto md:px-2 md:py-2 text-white sm:text-2xl text-sm font-semibold">
          <div>{title}</div>
          <div>
            <FontAwesomeIcon icon={faPhoneSquare} />
            <span className="pl-2"><a href="tel:8168307575">816.830.7575</a></span>
            <span className="pl-5"/>
            <FontAwesomeIcon icon={faEnvelope} />
            <span className="pl-2"><a href="mailto:jgo4th@aol.com">jgo4th@aol.com</a></span>
          </div>
        </div>
      </div>
    </>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
